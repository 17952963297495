import React, { useCallback, useEffect, useState } from 'react'
import {
  List as AdminList,
  Datagrid,
  Filter,
  TextField,
  SearchInput,
  SelectInput,
  ListProps,
  DateField,
  DateInput,
  BooleanInputProps,
  useListContext,
} from 'react-admin'
import { Button } from '@mui/material'
import LocalFireDepartment from '@mui/icons-material/LocalFireDepartment'
import UpdateIcon from '@mui/icons-material/Update';
import ReceiveMethodField from 'src/components/resources/orders/ReceiveMethodField'
import ReturnMethodField from 'src/components/resources/orders/ReturnMethodField'
import StatusField from 'src/components/resources/shared/StatusField'
import OrderCodeField from 'src/components/resources/orders/OrderCodeField'

const statuses = [
  'pending_receipt',
  'received',
  'invoiced',
  'approved',
  'repaired',
  'pending_return',
  'returned'
]

const receiveMethods = [{
  id: 'store_dropoff',
  name: 'Store Dropoff'
}, {
  id: 'diy_shipping',
  name: 'DIY Mail-In'
}, {
  id: 'shipping_label',
  name: 'UPS Shipping Label'
}, {
  id: 'ups_dropoff',
  name: 'UPS Store Dropoff'
}, {
  id: 'standard_courier',
  name: 'Standard Courier'
}, {
  id: 'ondemand_courier',
  name: 'On-Demand Courier'
}, {
  id: 'locker_dropoff',
  name: 'Golocker Dropoff'
}]

const returnMethods = [{
  id: 'store_pickup',
  name: 'Store Pickup'
}, {
  id: 'ups_shipping',
  name: 'UPS Shipping'
}, {
  id: 'standard_courier',
  name: 'Courier Delivery'
}]

type ButtonInputProps = {
  icon: any
} & Omit<BooleanInputProps, 'options'>

const ButtonInput = (props: ButtonInputProps) => {
  const { setFilters, filterValues } = useListContext()
  const [isActive, toggleActive] = useState(false)
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    toggleActive(!isActive)
  }, [isActive])

  useEffect(() => {
    const filters = filterValues
    if (isActive) filters[props.source] = true
    else delete filters[props.source]
    setFilters(filters, {})
  }, [isActive, props.source])

  return (
    <Button
      size="small"
      color="primary"
      sx={{ py: 1, mb: 0.375 }}
      variant={isActive ? "contained" : "outlined"}
      startIcon={props.icon}
      onClick={handleClick}
    >
      {/* @ts-ignore */}
      {props.source.titleize()}
    </Button>
  )
}

const List = (props: ListProps) => {
  return (
    <AdminList
      title="Repair Orders"
      actions={false}
      filters={(
        <Filter {...props}>
          <SearchInput source="q" alwaysOn />
          {/* @ts-ignore */}
          <SelectInput source="status" choices={statuses.map((status) => ({ id: status, name: status.titleize() }))} emptyText="Any" alwaysOn />
          <DateInput source="latest_status_at" label="Status since" format={(v: any) => v?.gte} parse={(gte: any) => ({ gte })} alwaysOn />
          <SelectInput source="receive_method" choices={receiveMethods} emptyText="Any" alwaysOn />
          <SelectInput source="return_method" choices={returnMethods} emptyText="Any" alwaysOn />
          <ButtonInput source="is_rush" icon={<LocalFireDepartment />} alwaysOn />
          <ButtonInput source="future_price" icon={<UpdateIcon />} alwaysOn />
        </Filter>
      )}
      sort={{ field: "latest_status_at", order: 'DESC' }}
      {...props}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <OrderCodeField label="Order" />
        <StatusField label="Status" source="status" />
        <DateField label="Status since" source="latest_status_at" />
        <ReceiveMethodField label="Receive Method" displayTime={false} />
        <ReturnMethodField label="Return Method" />
        <TextField source="storefront.name" label="Storefront" sortable={false} />
      </Datagrid>
    </AdminList>
  )
}

export default List
