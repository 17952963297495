import React from 'react'
import {
  Edit as AdminEdit,
  EditProps,
  useRecordContext,
  useEditContext,
  EditActionsProps
} from 'react-admin'
import PrintButton from 'src/components/resources/shared/PrintButton';
import ItemDetails from 'src/components/resources/order_items/ItemDetails';
import env from 'src/env'
import Card from '@mui/material/Card';

const Actions = (props: EditActionsProps) => {
  const record = useRecordContext();
  if (!record) return <></>;
  return (
    <div style={{ textAlign: 'right', marginTop: 10, marginBottom: 10 }}>
      <PrintButton style={{ marginLeft: 5 }} label="Print Store Ticket" url={`${env.API_URL}/rest/order_items/${record.id}.png`} disabled={record.status === 'pending_receipt'} />
    </div>
  )
}

const ItemContext = () => {
  const item = useRecordContext();
  return <ItemDetails order={item.order} item={item} />
}

const Edit = (props: EditProps) => {
  const Title = () => {
    const item = useRecordContext();
    const { isLoading } = useEditContext();
    if (isLoading) return null;
    return (
      <span>
        #<strong>{item.code} &ndash; {item.repairable_name}</strong>
      </span>
    )
  }
  return (
    <AdminEdit title={<Title />} actions={<Actions />} {...props}>
      <Card sx={{ p: 2}}>
        <ItemContext />
      </Card>
    </AdminEdit>
  )
}

export default Edit;
