import * as React from 'react';
import { BooleanInput, ShowActionsProps, useRecordContext } from 'react-admin';
import env from 'src/env';
import EditDialog from 'src/components/EditDialog';
import { LocalFireDepartment } from '@mui/icons-material';
import PrintButton from '../shared/PrintButton';

const Actions = (props: ShowActionsProps) => {
  const record = useRecordContext();

  if (!record) return <></>;
  return (
    <div style={{ textAlign: 'right', marginTop: 10, marginBottom: 10 }}>
      <EditDialog resource="orders" record={record} label={<>
        <LocalFireDepartment fontSize="small" />
        Toggle Rush
      </>} description="Indicate whether order should be rushed." buttonVariant="outlined">
        <BooleanInput label="Toggle Rush" source="is_rush" fullWidth />
      </EditDialog>
      <PrintButton label="Packing Slip" url={`${env.API_URL}/rest/orders/${record.id}.pdf`} style={{ marginLeft: 5 }} />
      <PrintButton style={{ marginLeft: 5 }} label="Customer Ticket" url={`${env.API_URL}/rest/orders/${record.id}.png`} disabled={record.status === 'pending_receipt'} />
      {record.status === 'pending_receipt' && <PrintButton style={{ marginLeft: 5 }} label="Customer Shipping Label" url={record.receipt_shipping_label_url} disabled={!record.receipt_shipping_label_url} />}
      {record.status === 'pending_return' && <PrintButton style={{ marginLeft: 5 }} label="Return Shipping Label" url={record.return_shipping_label_url} disabled={!record.return_shipping_label_url} />}
    </div>
  )
}

export default Actions;
