import * as React from 'react';
import { FieldProps, FunctionField, RaRecord } from 'react-admin';
import StarsIcon from '@mui/icons-material/Stars';
import LocalFireDepartment from '@mui/icons-material/LocalFireDepartment';
import { Chip } from '@mui/material';

const ItemCodeField = (props: FieldProps) => {
  return <FunctionField {...props} render={(record: RaRecord) => {
    return (
      <>
        <strong>{record.order.confirmation_code}-{record.number}</strong>
        <span style={{ float: 'right' }}>
          {record.is_vip && <>&nbsp;&nbsp;<Chip label="VIP" size="small" color="error" variant="outlined" icon={<StarsIcon />} /></>}
          {!!record.rush_fee && <>&nbsp;&nbsp;<Chip label="Rush" size="small" color="error" variant="outlined" icon={<LocalFireDepartment />} /></>}
        </span>
      </>
    )
  }} />
}

export default ItemCodeField;
