import * as React from 'react';
import { useGetList } from 'ra-core';
import { Controller, Control } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function QuotePerformerInput ({ control }: {
  control: Control<{ performed_by_id: any; }>
}) {
  const { data: employees = [], isLoading } = useGetList('employees', {
    filter: { active: true },
    pagination: { perPage: 1000, page: 1 },
    sort: { field: 'old_code', order: 'ASC' },
  }, { cacheTime: 3600 });

  const employeeOptions = React.useMemo(() => {
    return employees.map(employee => ({
      label: `${employee.old_code} - ${employee.name}`,
      id: employee.id
    }));
  }, [employees])

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!employees.length) {
    return <></>;
  }
  return (
    <Controller
      name="performed_by_id"
      control={control}
      render={({ field }) => (
        <Autocomplete
          options={employeeOptions}
          onChange={(_, data) => field.onChange(data ? data.id : '')}
          renderInput={(params) => <TextField {...params} label="Employee Code" required={true} />
          }
        />
      )}
    />
  )
}
