import { fetchUtils, DataProvider } from "react-admin";
import { DirectUpload } from "activestorage";
import merge from "src/utilities/merge";
import serializeParams from "src/utilities/serializeParams";

import env from "src/env";

const uniqueById = (arr: any[]) => [
  ...new Map(arr.map((item) => [item.id, item])).values(),
];

const getHeaders = () => {
  let headers = new Headers();
  if (localStorage.getItem("ls_jwt")) {
    headers.set("Authorization", `Bearer ${localStorage.getItem("ls_jwt")}`);
  }
  return headers;
};

const singularize = (resource: string) => {
  const irregular: { [key: string]: string } = {
    addresses: "address",
  };
  if (irregular.hasOwnProperty(resource)) return irregular[resource];
  return resource.replace("/", "_").substring(0, resource.length - 1);
};

const resourceQueryParams: { [x: string]: object } = {
  bins: {
    include: {
      items: true,
      order: {
        storefront: true
      },
    },
  },
  inquiries: {
    include: {
      estimate: true,
      category: true,
      formatted_estimate: true,
    },
  },
  quotes: {
    include: {
      before_photo_url: true,
      after_photo_url: true,
      performed_by: true,
      assessed_by: true,
    },
  },
  rates: {
    include: {
      repairable: true,
      service: true,
    },
  },
  services: {
    include: {
      rates: true,
    },
  },
  orders: {
    include: {
      amount_paid: true,
      amount_due: true,
      user: true,
      storefront: true,
      receipt_shipping_label_url: true,
      return_shipping_label_url: true,
      sortable_item_count: true,
      items: {
        code: true,
        repairable_name: true,
      },
    },
  },
  order_items: {
    include: {
      bin: true,
      code: true,
      formatted_description: true,
      formatted_estimate: true,
      photo_url: true,
      repairable: true,
      repairable_name: true,
      order: {
        items: true,
        corporate_account: true,
        storefront: true,
      },
      inquiries: {
        category: true,
        formatted_estimate: true,
      },
      quotes: {
        before_photo_url: true,
        after_photo_url: true,
        performed_by: true,
        assessed_by: true,
      },
      should_sort: true,
      promotion: true,
    },
  },
  payments: {
    include: {
      formatted_notes: true,
    },
  },
  promotions: {
    include: {
      user: true,
    },
  },
  users: {
    include: {
      corporate_account: true,
      name: true,
      orders: true,
    },
  },
};

// presumes has_one_attached association
const blobKeyMap: {
  [x: string]: string[];
} = {
  "order_items": ["photo"],
  "quotes": ["before_photo", "after_photo"],
};

interface ICreateBlobSignedKey {
  rawFile: File;
}

const createBlobSignedKey = async function (photo: ICreateBlobSignedKey) {
  if (!(photo && photo.rawFile instanceof File)) {
    return Promise.resolve([]);
  }
  const railsActiveStorageDirectUploadsUrl = `${env.API_URL}/rails/active_storage/direct_uploads`;
  return new Promise((resolve, reject) => {
    const upload = new DirectUpload(
      photo.rawFile,
      railsActiveStorageDirectUploadsUrl
    );
    upload.create((error, blob) => {
      if (error) reject(error);
      else resolve(blob.signed_id);
    });
  });
};

const httpClient = fetchUtils.fetchJson;

type QueryParams = {
  [x: string]: string | boolean;
};

// eslint-disable-next-line import/no-anonymous-default-export
const dataProvider: DataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order: sort } = params.sort;
    const queryParams: QueryParams = {
      [`sort[${field}]`]: sort,
      limit: JSON.stringify(perPage),
      offset: JSON.stringify((page - 1) * perPage),
      where: params.filter,
      // distinct: "id", // because standardapi does full outer join, ensure distinct rows
    };
    const query = merge({}, queryParams, resourceQueryParams[resource]);
    const serializedParams = serializeParams(query);
    const response = await httpClient(
      `${env.API_URL}/rest/${resource}.json?${serializedParams}`,
      { headers: getHeaders() }
    );
    const countResponse = await httpClient(
      `${env.API_URL}/rest/${resource}/calculate?${serializedParams}&select[count]=id&distinct=id`,
      { headers: getHeaders() }
    );
    return {
      data: uniqueById(response.json),
      total: countResponse.json[0],
    };
  },

  getOne: async (resource, params) => {
    const serializedParams = serializeParams(resourceQueryParams[resource]);
    const response = await httpClient(
      `${env.API_URL}/rest/${resource}/${params.id}.json?${serializedParams}`,
      { headers: getHeaders() }
    );
    return {
      data: response.json,
    };
  },

  getMany: async (resource, params) => {
    const queryParams: QueryParams = {
      "where[id]": params.ids.join(","),
      limit: JSON.stringify(1000),
    };
    const query = merge({}, queryParams, resourceQueryParams[resource]);
    const response = await httpClient(
      `${env.API_URL}/rest/${resource}.json?${serializeParams(query)}`,
      { headers: getHeaders() }
    );

    return {
      data: JSON.parse(response.body),
    };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order: sort } = params.sort;
    const queryParams: QueryParams = {
      [`sort[${field}]`]: sort,
      limit: JSON.stringify(perPage),
      offset: JSON.stringify((page - 1) * perPage),
      where: {
        [params.target]: params.id,
        ...params.filter,
      },
      // distinct: "id", // because standardapi does full outer join, ensure distinct rows
    };
    const query = merge({}, queryParams, resourceQueryParams[resource]);
    const serializedParams = serializeParams(query);
    const response = await httpClient(
      `${env.API_URL}/rest/${resource}.json?${serializedParams}`,
      { headers: getHeaders() }
    );
    const countResponse = await httpClient(
      `${env.API_URL}/rest/${resource}/calculate?${serializedParams}&select[count]=id&distinct=id`,
      { headers: getHeaders() }
    );
    return {
      data: uniqueById(response.json),
      total: countResponse.json[0],
    };
  },

  update: async (resource, params) => {
    const patchParams: any = {};
    for (const key of Object.keys(params.data)) {
      if (blobKeyMap[resource] && blobKeyMap[resource].includes(key)) {
        patchParams[key] = await createBlobSignedKey(params.data[key]);
      } else if (params.data[key] !== params.previousData[key]) {
        patchParams[key] = params.data[key];
      }
    }
    const response = await httpClient(
      `${env.API_URL}/rest/${resource}/${params.id}.json`,
      {
        method: "PATCH",
        headers: getHeaders(),
        body: JSON.stringify({
          [singularize(resource)]: patchParams,
        }),
      }
    );
    return {
      data: JSON.parse(response.body),
    };
  },

  updateMany: async (resource, params) => {
    let data = [];
    for (const id of params.ids) {
      const response = await httpClient(
        `${env.API_URL}/rest/${resource}/${id}`,
        {
          method: "PATCH",
          headers: getHeaders(),
          body: JSON.stringify({
            [singularize(resource)]: params.data,
          }),
        }
      );
      data.push(JSON.parse(response.body));
    }
    return {
      data,
    };
  },

  create: async (resource, params) => {
    const response = await httpClient(`${env.API_URL}/rest/${resource}.json`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        [singularize(resource)]: params.data,
      }),
    });
    return {
      data: JSON.parse(response.body),
    };
  },

  delete: async (resource, params: any) => {
    await httpClient(`${env.API_URL}/rest/${resource}/${params.id}.json`, {
      method: "DELETE",
      headers: getHeaders(),
    });
    return {
      data: params.previousData,
    };
  },

  deleteMany: async (resource, params) => {
    const query = {
      where: JSON.stringify({ id: params.ids }),
    };
    await httpClient(
      `${env.API_URL}/rest/${resource}?${serializeParams(query)}`,
      {
        method: "DELETE",
        headers: getHeaders(),
      }
    );
    return {
      // @ts-ignore
      data: params.previousData,
    };
  },
};

export default dataProvider;
