import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { useNotify } from 'react-admin'
import dataProvider from './data'
import env from 'src/env'

interface SortingContextType {
  query?: string
  code?: Code
  setQuery: (query: string) => void
  orderItem: any | undefined
}

const SortingContext = createContext<SortingContextType | undefined>(undefined)

export type Code = 'not_found' | 'not_ready' | 'canceled' | 'item_incomplete' | 'item_complete' | 'order_complete' | 'outbound' | 'unknown'

export type Display = {
  showCount: boolean
  textColor: 'black' | 'white'
  backgroundColor: 'red' | 'green' | 'blue' | 'yellow' | 'transparent'
  message: string
}

// handle all statuses except approved.
const statusToCode = {
  'canceled': 'canceled',
  'repaired': 'order_complete',
  'pending_receipt': 'not_ready',
  'received': 'not_ready',
  'invoiced': 'not_ready',
  'pending_return': 'outbound',
  'returned': 'outbound',
}

export const SortingScreenProvider = ({ children }: { children: ReactNode }) => {
  const notify = useNotify()
  const token = localStorage.getItem(env.TOKEN_KEY)
  const [query, setQuery] = useState<string>()
  const [code, setCode] = useState<Code>()
  const [orderItem, setOrderItem] = useState<Record<string, unknown>>()

  const fetchOrderItem = async (debouncedValue: string) => {
    try {
      const response = await dataProvider.getList('order_items', {
        filter: { q: `"${debouncedValue}"` },
        sort: { field: 'created_at', order: 'ASC' },
        pagination: { page: 1, perPage: 1 },
      })
      if (response.data.length === 0) {
        setOrderItem(undefined)
        setQuery(debouncedValue)
        setCode('not_found')
      } else {
        const orderItem = response.data.find((item: any) => item.code === debouncedValue)
        setOrderItem({ ...orderItem })
        // approved: check if scanned item is complete or not.
        const code = (statusToCode[orderItem.order.status as keyof typeof statusToCode] ||
          (orderItem.should_sort ? 'item_complete' : 'item_incomplete')) as Code
          setQuery(debouncedValue)
          setCode(code)
      }
    } catch (error) {
      setOrderItem(undefined)
      setQuery(debouncedValue)
      setCode('not_found')
    }
  }

  const trackScan = async () => {
    const item = orderItem!
    try {
      const response = await fetch(`${env.API_URL}/rest/order_items/${item.id}/track_scan`, {
        method: 'POST',
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      })
      if (response.status >= 400) {
        const data = await response.json()
        const errArray = Object.entries(data.errors)[0]
        // @ts-ignore
        const error = errArray[0].titleize() + ' ' + errArray[1][0]
        notify(`Error: ${error}.`, { type: 'warning' })
      } else {
        const { data } = await dataProvider.getOne('order_items', { id: item.id })
        setOrderItem(data)
        notify('Scan time recorded')
      }
    } catch (error) {
      notify('Server error. Contact the developer', { type: 'error' })
    }
  }

  const refetchOrderItem = () => {
    if (query) {
      fetchOrderItem(query)
    }
  }

  useEffect(() => {
    refetchOrderItem()
  }, [query])

  useEffect(() => {
    if (code) {
      if (['not_ready', 'canceled', 'item_incomplete', 'item_complete', 'order_complete'].includes(code)) {
        trackScan()
      }
    }
  }, [code])

  return (
    <SortingContext.Provider value={{ code, query, setQuery, orderItem }}>
      {children}
    </SortingContext.Provider>
  )
}

export const useSortingContext = () => {
  const context = useContext(SortingContext)
  if (!context) {
    throw new Error('useSortingContext must be used within a SortingScreenProvider')
  }
  return context
}
