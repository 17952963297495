import * as React from 'react';
import { WrapperField, FieldProps, useRecordContext } from 'react-admin';
import {
  Typography,
  Link
} from '@mui/material';

const ReceiptTrackingNumberField = (props: FieldProps) => {
  const record = useRecordContext();
  if (!!record.receipt_datatrac_id) {
    <WrapperField>
      <div style={{ display: 'flex' }}>
        <Typography variant="body2">
          {record.receipt_datatrac_id}{' '}
          <small>
            <Link href={`https://login.datatrac.com/tracking/show_order?id=${record.receipt_datatrac_id}`} color="secondary">
              View in Datatrac
            </Link>
          </small>
        </Typography>
      </div>
    </WrapperField>
  } else if (!!record.receipt_ups_tracking_number) {
    return (
      <WrapperField>
        <div style={{ display: 'flex' }}>
          <Typography variant="body2">
            {record.receipt_ups_tracking_number}{' '}
            <small>
              <Link href={`https://www.ups.com/track?loc=en_US&tracknum=${record.receipt_ups_tracking_number}&requester=WT/trackdetails`} color="secondary">
                Track via UPS
              </Link>
            </small>
          </Typography>
        </div>
      </WrapperField>
    )
  }
  return <Typography variant="body2">No tracking number</Typography>
}

export default ReceiptTrackingNumberField;

