import * as React from 'react';
import { WrapperField, FieldProps, useRecordContext } from 'react-admin';
import {
  Typography,
  Link
} from '@mui/material';

const ReturnTrackingNumberField = (props: FieldProps) => {
  const record = useRecordContext();
  if (!!record.return_datatrac_id) {
    return (
      <WrapperField>
        <div style={{ display: 'flex' }}>
          <Typography variant="body2">
            {record.return_datatrac_id}{' '}
            {record.return_datatrac_id ? (
              <small>
                <Link href={`https://login.datatrac.com/tracking/show_order?id=${record.return_datatrac_id}`} color="secondary">
                  View in Datatrac
                </Link>
              </small>
            ) : 'No tracking number yet.'}
          </Typography>
        </div>
      </WrapperField>
    )
  } else if (!!record.return_ups_tracking_number) {
    return (
      <WrapperField>
        <div style={{ display: 'flex' }}>
          <Typography variant="body2">
            {record.return_ups_tracking_number}{' '}
            {record.return_ups_tracking_number ? (
              <>
                <small>
                  <Link href={`https://www.ups.com/track?loc=en_US&tracknum=${record.return_ups_tracking_number}&requester=WT/trackdetails`} color="secondary">
                    Track via UPS
                  </Link>
                </small>
              </>
            ) : 'No return label yet.'}
          </Typography>
        </div>
      </WrapperField>
    )
  }
  return <Typography variant="body2">No tracking number.</Typography>
}

export default ReturnTrackingNumberField;

